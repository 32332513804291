export const countries = [
    { code: '+7', value: 'RU', fullName: 'Russian Federation' },
    { code: '+1', value: 'US', fullName: 'United States of America' },
    { code: '+7', value: 'KZ', fullName: 'Kazakhstan' },
    { code: '+380', value: 'UA', fullName: 'Ukraine' },
    { code: '+375', value: 'BY', fullName: 'Belarus' },
    { code: '+49', value: 'DE', fullName: 'Germany' },
    { code: '+90', value: 'TR', fullName: 'Turkey' },
    { code: '+44', value: 'GB', fullName: 'United Kingdom' },
    { code: '+971', value: 'AE', fullName: 'United Arab Emirates' },
    { code: '+886', value: 'TW', fullName: 'Taiwan' },
    { code: '+998', value: 'UZ', fullName: 'Uzbekistan' },
    { code: '+996', value: 'KG', fullName: 'Kyrgyzstan' },
    { code: '+', value: 'WD', fullName: 'Another Country' },
];
