<script lang="ts">
  import { staticsStore } from '../../store';
  import { fly, fade } from 'svelte/transition';
  export let HEADER_FADE_IN_DURATION = 600;
  export let FLY_IN_DURATION = 1000;

  let statics = null;
  let isReady = false;

  staticsStore.subscribe((value) => {
    statics = value;
    if (value !== null) {
      // Start the animation when we successfully load all required data.
      // TODO: $: isReady = statics !== null; doesn't work, idk why.
      isReady = true;
    }
  });
</script>

<div class="Background">
  {#if isReady}
    <div class="Background__bubbleContainer">
      <div in:fade={{ duration: HEADER_FADE_IN_DURATION }} class="Background__doctorContainer">
        <div class="Background__avatarContainer">
          <img
            class="Background__avatar"
            src={statics && statics.doctor && statics.doctor.avatar}
            alt={statics && statics.doctor && statics.doctor.full_name}
          />
        </div>
        <div class="Background__doctorCard">
          <div class="Background__doctorCard__name">
            {statics && statics.doctor && statics.doctor.full_name}
          </div>
        </div>
      </div>
      <div
        in:fly={{ y: 60, duration: FLY_IN_DURATION, delay: HEADER_FADE_IN_DURATION }}
        class="filter"
      >
        <div class="Background__bubble Background__bubble-wide Background__bubble-first">
          Здравствуйте, теперь вы можете задать мне все вопросы онлайн!
        </div>
        <div class="triangle" />
      </div>
      <div
        in:fly={{ y: 60, duration: FLY_IN_DURATION, delay: HEADER_FADE_IN_DURATION + FLY_IN_DURATION }}
        class="filter"
      >
        <div class="Background__bubble Background__bubble-wide">
          Для этого зарегистрируйтесь и скачайте приложение.
        </div>
        <div class="triangle" />
      </div>
      <div
        in:fly={{ y: 60, duration: FLY_IN_DURATION, delay: HEADER_FADE_IN_DURATION + 2 * FLY_IN_DURATION }}
        class="filter"
      >
        <div class="Background__bubble Background__bubble-narrow">До встречи в приложении!</div>
        <div class="triangle" />
      </div>
    </div>
  {/if}
</div>

<style>
  .Background {
    background-color: var(--silver);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 16px 24px;
  }

  .Background__doctorContainer {
    display: flex;
  }

  .Background__avatarContainer {
    display: flex;
    flex-basis: 100px;
    flex-shrink: 0;
    min-height: 100px;
    max-height: 100px;
    border-radius: 50%;
    border: 1px solid var(--mainColor);
    justify-content: center;
    align-items: center;
  }

  .Background__avatar {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    border-radius: 50%;
  }

  .Background__doctorCard {
    background: #ffffff;
    box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 8px 16px;
    margin-left: 16px;
    margin-top: 10px;
    flex-grow: 1;
    align-self: flex-start;
  }

  .Background__doctorCard__name {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .Background__bubbleContainer {
    display: flex;
    flex-direction: column;
  }

  .Background__bubble {
    display: flex;
    flex-grow: 0;
    border-radius: 30px;
    background-color: #ffffff;
    padding: 8px 16px;
    margin-top: 24px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .filter {
    /* 4px colorful shadow looks like too much, idk */
    filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.08)) drop-shadow(0px 1px 2px var(--mainColor));
  }

  .triangle {
    width: 0;
    height: 0;
    position: absolute;
    border-left: 16px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 16px solid white;
    transform: translate3d(18px, -8px, 0px) rotate(-45deg);
  }

  @supports (-webkit-touch-callout: none) {
    /* Separate filter for mobile safari only cause it filter on parent is not working correctly there*/
    /* Have to make it smaller larger shadow breaks stuff */
    .triangle {
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-bottom: 12px solid white;
      filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.08))
        drop-shadow(-3px 2px 2px var(--mainColor));
      transform: translate3d(18px, -4px, 0px) rotate(-45deg);
    }
  }

  .Background__bubble-first {
    margin-top: 12px;
  }

  .Background__bubble-wide {
    max-width: 324px;
  }

  .Background__bubble-narrow {
    max-width: 234px;
  }
</style>
