<script lang="ts">
  import Logo from '../Background/assets/ibolit-logo.svg';
  import AppStoreIcon from './assets/app-store.svg';
  import GooglePlayIcon from './assets/google-play.svg';
  import { staticsStore } from '../../store';

  export let isAndroid: boolean = false;
  export let isIOS: boolean = true;

  let statics = null;
  staticsStore.subscribe((value) => {
    statics = value;
  });

  let IOS_LINK = 'SHOULD_BE_FETCHED_FROM_BACKEND_STATICS.WL_URL';
  let ANDROID_LINK = 'SHOULD_BE_FETCHED_FROM_BACKEND_STATICS.WL_URL';

  if (statics) {
    if (statics.wl_url !== null) {
      IOS_LINK = statics.wl_url;
      ANDROID_LINK = statics.wl_url;
    }
  }
</script>

<div class="Success">
  <Logo class="Success__logo" width={148} height={58} />
  <h3>Вы успешно зарегистрировались</h3>
  <p>На указанные данные придет сообщение со ссылкой на скачивание приложения</p>
  {#if isAndroid}
    <a href={ANDROID_LINK} target="_blank" rel="noopener noreferrer">
      <GooglePlayIcon class="Success__download-logo" width={147} height={42} />
    </a>
  {:else if isIOS}
    <a href={IOS_LINK} target="_blank" rel="noopener noreferrer">
      <AppStoreIcon class="Success__download-logo" width={147} height={42} />
    </a>
  {/if}
</div>

<style>
  h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
    text-align: center;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    margin: 8px 0;
    text-align: center;
  }

  .Success {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 23px;
  }

  :global(.Success__logo) {
    margin-bottom: 32px;
  }

  :global(.Success__download-logo) {
    margin-top: 40px;
  }
</style>
