<script lang="ts">
  import FailureIcon from './assets/failure.svg';
  import Button from '../common/Button/Button.svelte';

  export let error: string = '';
  export let isSetupError: boolean = false;
</script>

<div class="Failure">
  <FailureIcon class="Failure__logo" fill="var(--danger)" width={72} height={72} />
  <h3>{error || 'Что-то пошло не так'}</h3>
  {#if !isSetupError}
    <p>Попробуйте повторить попытку</p>
    <Button on:click>Повторить еще раз</Button>
  {/if}
  {#if isSetupError}
    <p style="text-align: center">
      Сломались настройки виджета<br />
      Обратитесь в службу поддержки
    </p>
  {/if}
</div>

<style>
  h3 {
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    margin: 0;
    text-align: center;
  }

  p {
    margin: 8px 0;
    line-height: 24px;
  }

  .Failure {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding: 23px;
  }

  /*
   Scoped styles don't work when passed to another component
   https://github.com/sveltejs/svelte/issues/2870
  */
  :global(.Failure__logo) {
    margin-bottom: 32px;
  }
</style>
